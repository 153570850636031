import revive_payload_client_vY34ILFWiwf95Wz9t55tHTca5aL8lkyQIGYgC5toWGo from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2qYhb1xDO3Ww738nnT0_b4sC1Hm_IajYwQquluMAAw8 from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vLb9jEbvEm9LkNlrkr7KczsWAamLYnP5IsRgSf4BAcs from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig__XpxosqC_9DmvyA6VstMyN_TXEGnmpW1WT1VM2UdZkk from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_terse_fa6e5bb9605e96033af7a8e14408468f/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client__8mMwVgJtm8zDrQ9XMCVMWW9_HaGXCx298Y0A1wrsr4 from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GeeqjAZB67pw4_kCic_W3i68VMFjzvSmknOajWegDZk from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bm9BBJxojK3s_iu36l7qRvn92ahGL3tZJApGPXNtuTo from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2uSQS384Vb29h30tLnZmej5rMrVjHRXzHeSBPkxz6ZU from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/.nuxt/components.plugin.mjs";
import prefetch_client_avi4EjVoXhgfAeRO6JyIoL4PXjIEin3TMa8ZS4FzBSk from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_H96gbFWxrfUEcddEy0zz3BCD_BArOapugDJfsTiSzW0 from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+algolia@1.11.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.8.2___magica_57885559ad0e4c2ac9be8101f6d418be/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_1mXvcvw8_1IH2PDwq9yURjD_sxli1mS1NGVZ94fBJ1E from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.2_@parcel+watcher@2.5.1_@type_79e86f26ec166559b32f58a174e4aa4a/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_6uGsFWm9CZAu_S3rOEeJZrsLGQEC2alM8lBrGU_7nTA from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_terser@5.39_e7e19a8c1bfb06e764752a7386a60019/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8 from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_6cc402edc64b5b431717c79808b7ec48/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_KhRnvPH_3WLgMp1GPtWVqlocqCV3hc9e3lBr4gyix3M from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_SCn1vGYeTulmveUeb7VEcX8l5nztxYToi243T_Aum6k from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EfzintqZOG70gQ9Mn9sGWHXYirS__so2ktUr5HWyaQE from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import ssg_detect_eMfC1h_kLw_IxnCUiKBAQvWIeptKW5LVgUxqpAONedM from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_vY34ILFWiwf95Wz9t55tHTca5aL8lkyQIGYgC5toWGo,
  unhead_2qYhb1xDO3Ww738nnT0_b4sC1Hm_IajYwQquluMAAw8,
  router_vLb9jEbvEm9LkNlrkr7KczsWAamLYnP5IsRgSf4BAcs,
  _0_siteConfig__XpxosqC_9DmvyA6VstMyN_TXEGnmpW1WT1VM2UdZkk,
  payload_client__8mMwVgJtm8zDrQ9XMCVMWW9_HaGXCx298Y0A1wrsr4,
  navigation_repaint_client_GeeqjAZB67pw4_kCic_W3i68VMFjzvSmknOajWegDZk,
  check_outdated_build_client_bm9BBJxojK3s_iu36l7qRvn92ahGL3tZJApGPXNtuTo,
  chunk_reload_client_2uSQS384Vb29h30tLnZmej5rMrVjHRXzHeSBPkxz6ZU,
  plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_avi4EjVoXhgfAeRO6JyIoL4PXjIEin3TMa8ZS4FzBSk,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  plugin_H96gbFWxrfUEcddEy0zz3BCD_BArOapugDJfsTiSzW0,
  plugin_1mXvcvw8_1IH2PDwq9yURjD_sxli1mS1NGVZ94fBJ1E,
  plugin_6uGsFWm9CZAu_S3rOEeJZrsLGQEC2alM8lBrGU_7nTA,
  plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg,
  plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8,
  switch_locale_path_ssr_KhRnvPH_3WLgMp1GPtWVqlocqCV3hc9e3lBr4gyix3M,
  route_locale_detect_SCn1vGYeTulmveUeb7VEcX8l5nztxYToi243T_Aum6k,
  i18n_EfzintqZOG70gQ9Mn9sGWHXYirS__so2ktUr5HWyaQE,
  base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE,
  ssg_detect_eMfC1h_kLw_IxnCUiKBAQvWIeptKW5LVgUxqpAONedM
]