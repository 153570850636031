
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthPOCX_45aXAz2BuhFSNkQMiCLm4zUTBRXGBNTjTVx4_gGYMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93N9HLNUBOgZZUD6lwmJYqIgurseHF_453pfggX5ojWv2WwMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue?macro=true";
import { default as guidesSJpeBi7okZZnE6eHngh5yLNrBt_45pop1PzaBz5KBsjZUMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue?macro=true";
import { default as port_45parkingwCmnyWbAmDvz3flcm1rI3l_JUiEy1ULUZ2GcuEmeeVUMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue?macro=true";
import { default as airport_45parkingw_45QTX2ozBf1Nk665Se5fk4EM57J1JQhBhHSkh5OF0Y8Meta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue?macro=true";
import { default as parking_45couponsEByaCShL7MHbUeEMkhNP1L5QUfJ02_45cRh5eZSUu2lK4Meta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue?macro=true";
import { default as _91result_93k_MRO22VHh_45p5bO6Lo_lS9ncJA7dxFbFjrsMZDP6WwAMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue?macro=true";
import { default as airport_45shuttlesHyFI1Q3d_YZhaOyJxT0YSt7LYsjhfI5Uwbp17vCMtXsMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93pVLVGAkjZsLp9p1eyMNdRoQKVDy__45rPYF30WIW5pfUkMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as off_45airport_45parkingrs6srqOicDSNR9Y8UUgEkEOJfTgfg6kFlQFV1I_60dcMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_938qsAZA2jfG6ui6ZrqwVWOkBqrIBXmXxXc571VPHyeZ4Meta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as port_45hotels_45with_45parkingKli8WrQ2f9pjnggYj_457WciWz2cK_454XH_455pD92HsMnr4Meta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as airport_45hotels_45with_45parkingWvdclP_45wSPC3Dboc1QFLKlwtb92E5OGwvMcqalFwg38Meta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93cg2l5XvqGfFBYI_45576QUSu1AnMinG3UMA2YSCcxfk2UMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubDYsng_45Lwz2Hq_b_45a7aH9Y5jUlPj8RX9C3FouCCCo0SoMeta } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubDYsng_45Lwz2Hq_b_45a7aH9Y5jUlPj8RX9C3FouCCCo0So } from "/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@8.57.1_ioredis@_e922c7db2082e25cce062e9c537d3ce0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue")
  },
  {
    name: "IATA-guides___en",
    path: "/:IATA()/guides",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-parking-coupons___en",
    path: "/:IATA()/parking-coupons",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue")
  },
  {
    name: "flight-status-result___en",
    path: "/flight-status/:result()",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue")
  },
  {
    name: "IATA-airport-shuttles___en",
    path: "/:IATA()/airport-shuttles",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-off-airport-parking___en",
    path: "/:IATA()/off-airport-parking",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "Guides___en",
    path: "/:IATA([a-z]{3})",
    component: () => import("/codebuild/output/src3432717867/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: component_45stubDYsng_45Lwz2Hq_b_45a7aH9Y5jUlPj8RX9C3FouCCCo0SoMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubDYsng_45Lwz2Hq_b_45a7aH9Y5jUlPj8RX9C3FouCCCo0So
  }
]